import React from "react";
import {
  TideItemForAgentPage,
  TideItemForDestinations,
  TideItemForNavigationSection,
  TideItemForNotificationComponentConnection,
  TideItemForWebsite,
} from "../types";
import { LookupQueryData } from "../components/qsm";
import Layout from "../components/layout";
import { useI18next } from "gatsby-plugin-react-i18next";
import { first, isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import NotificationBar from "../components/notification-bar";
import Navbar from "../components/navbar";
import { graphql } from "gatsby";
import { AlternateRoute } from "../../.gatsby/gatsby-node";
import DossierList from "../components/portal/dossier-list";
import { useMemberStore } from "../app/member-store/context";
import InvoiceList from "../components/portal/invoice-list";

interface AgentPageTemplateProps {
  data: AgentPageData;
  pageContext: {
    language: string;
    alternateRoutes?: AlternateRoute[];
  };
}

const AgentPageTemplate: React.FC<AgentPageTemplateProps> = ({ data, pageContext }) => {
  const [memberState] = useMemberStore();
  const { t } = useI18next();
  const notificationBar = first(data.notifications?.nodes);
  const type = first(data.agentPage?.content?.general?.type) ?? "";

  return (
    <Layout>
      <Helmet title={data.agentPage?.content?.general?.title ?? "Agent page"} />
      {!isEmpty(notificationBar?.content?.general?.notification) && (
        <NotificationBar
          text={notificationBar?.content?.general?.notification ?? ""}
          icon={notificationBar?.content?.general?.iconFontAwesome ?? "fas fa-info"}
          backgroundColor={notificationBar?.content?.general?.backgroundColor ?? "#e74c3c"}
        />
      )}
      <Navbar
        alternateRoutes={pageContext?.alternateRoutes}
        mainNavigationSection={data.mainNavigationSection}
        fullscreenPrimaryNavigationSection={data.fullscreenPrimaryNavigationSection}
        fullscreenSecondaryNavigationSection={data.fullscreenSecondaryNavigationSection}
        socialNavigationSection={data.socialNavigationSection}
        legalNavigationSection={data.legalNavigationSection}
        phone={data.website?.content?.contact?.phone ?? ""}
        destinations={data.navigationDestinations}
      />
      {memberState?.member ? (
        <>
          <>{type === "Dossiers" && <DossierList pageData={data.agentPage}></DossierList>}</>
          <>{type === "Invoices" && <InvoiceList pageData={data.agentPage}></InvoiceList>}</>
        </>
      ) : (
        <>{t("NO_ACCESS")}</>
      )}
    </Layout>
  );
};

interface AgentPageData extends LookupQueryData {
  agentPage: TideItemForAgentPage;
  website: TideItemForWebsite;
  notifications: TideItemForNotificationComponentConnection;
  navigationDestinations: TideItemForDestinations;
  mainNavigationSection: TideItemForNavigationSection;
  fullscreenPrimaryNavigationSection: TideItemForNavigationSection;
  fullscreenSecondaryNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  contactNavigationSection: TideItemForNavigationSection;
}

export const query = graphql`
  query AgentPageQuery($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    agentPage: tideItemForAgentPage(id: { eq: $id }) {
      id
      content {
        general {
          title
          type
        }
      }
    }
    website: tideItemForWebsite(name: { eq: "Travelworld" }, language: { eq: $language }) {
      content {
        contact {
          phone
        }
      }
    }
    ...navigationDestinationsFragment
    notifications: allTideItemForNotificationComponent(filter: { language: { eq: $language } }) {
      nodes {
        content {
          general {
            notification
            iconFontAwesome
            backgroundColor
          }
        }
      }
    }
    mainNavigationSection: tideItemForNavigationSection(
      name: { eq: "Main Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    fullscreenPrimaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Primary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    fullscreenSecondaryNavigationSection: tideItemForNavigationSection(
      name: { eq: "Fullscreen Secondary" }
      language: { eq: $language }
    ) {
      ...fullscreenNavigationSectionFields
    }
    socialNavigationSection: tideItemForNavigationSection(
      name: { eq: "Social Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
    legalNavigationSection: tideItemForNavigationSection(
      name: { eq: "Legal Navigation" }
      language: { eq: $language }
    ) {
      childItems {
        ...navigationLinkFields
      }
    }
  }
`;

export default AgentPageTemplate;
