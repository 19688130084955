import React, { useEffect, useState } from "react";
import { TideItemForAgentPage } from "../../types";
import {
  getCountryList,
  getCustomEntryStatusList,
  getDossier,
  getEntryStatusList,
  printAction,
} from "../../tide-api";
import {
  AgentPrintActionRequest,
  Column,
  CountryItem,
  DossierViewResult,
  FilterItem,
  NumberStringPair,
} from "@qite/tide-client/build/types";
import { useI18next } from "gatsby-plugin-react-i18next";
import { addYears, format, getDate, getMonth, getYear } from "date-fns";
import Pagination from "react-responsive-pagination";
import { isEmpty } from "lodash";
import { CustomEntryStatusItem } from "@qite/tide-client/build/types";
import DateSelector from "./dateselector";
import Icon from "../icon";
import Loader from "../loader";
import { buildClassName } from "../../utils";
import Link from "../link";

interface DossierListProps {
  pageData?: TideItemForAgentPage;
}

const DossierList: React.FC<DossierListProps> = ({ pageData }) => {
  const itemsPerPage = 15;
  const creationDate = addYears(new Date(), -2);
  const offerPrintId = parseInt(process.env.OFFER_PRINT_ID!);
  const orderPrintId = parseInt(process.env.ORDER_PRINT_ID!);
  const voucherPrintId = parseInt(process.env.VOUCHER_PRINT_ID!);

  const { t } = useI18next();
  const [dossierList, setDossierList] = useState<DossierViewResult[]>([]);
  const [fetchDossiers, setFetchDossiers] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [entryStatusEnums, setEntryStatusEnums] = useState<NumberStringPair[]>([]);
  const [customEntryStatusList, setCustomEntryStatusList] = useState<CustomEntryStatusItem[]>([]);
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [createdDate, setCreatedDate] = useState<Date | null>(null);
  const [departureDate, setDepartureDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);
  const [doSearch, setDoSearch] = useState<boolean>(true);
  const [agentPrintActionRequest, setAgentPrintActionRequest] = useState<AgentPrintActionRequest>(
    {} as AgentPrintActionRequest
  );
  const [filter, setFilter] = useState<FilterItem>({
    take: itemsPerPage,
    page: 1,
    sort: {
      name: "number",
      asc: false,
    },
    columns: {
      createdDate: {
        type: "dateFrom",
        value: {
          year: getYear(creationDate),
          month: getMonth(creationDate) + 1,
          day: getDate(creationDate),
        },
      },
      number: {
        type: "text",
        value: undefined,
      },
      agentName: {
        type: "text",
        value: undefined,
      },
      contact: {
        type: "text",
        value: undefined,
      },
      firstProductName: {
        type: "text",
        value: undefined,
      },
      pax: {
        type: "list",
        value: undefined,
      },
      countryCodes: {
        type: "list",
        value: undefined,
      },
      totalPrice: {
        type: "numeric",
        value: undefined,
      },
    },
  } as FilterItem);

  const gridColumns = [
    { id: "pax", child: { dataType: "text", valueField: "name" } } as Column,
    { id: "countryCodes", child: { dataType: "text", isFlat: true } } as Column,
  ];

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      try {
        let token: string | null = null;
        if (typeof sessionStorage !== "undefined") {
          token = sessionStorage.getItem("token");
        }

        if (token && filter && doSearch) {
          setFetchDossiers(true);
          setError(false);
          setDoSearch(false);
          const entryList = await getDossier(filter, token, signal, gridColumns);
          if (entryList) {
            setDossierList(entryList.items);

            const totalPages = Math.ceil(entryList.count / itemsPerPage);
            setTotalPages(totalPages);
          }
        }
      } catch (error) {
        setError(true);
      } finally {
        setFetchDossiers(false);
      }
    })();
  }, [doSearch]);

  useEffect(() => {
    (async () => {
      const entryStatuses = await getEntryStatusList();
      entryStatuses.unshift({ key: undefined, value: "" });
      setEntryStatusEnums(entryStatuses);

      const customEntryStatuses = await getCustomEntryStatusList();
      customEntryStatuses.unshift({ name: "" } as CustomEntryStatusItem);
      setCustomEntryStatusList(customEntryStatuses);

      const countryList = (await getCountryList()).items;
      countryList.unshift({ id: 0, iso2: "", name: "" } as CountryItem);
      setCountries(countryList);
    })();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      let token: string | null = null;
      if (typeof sessionStorage !== "undefined") {
        token = sessionStorage.getItem("token");
      }

      if (token && agentPrintActionRequest && agentPrintActionRequest.id > 0) {
        const printResult = await printAction(agentPrintActionRequest, token, signal);
        if (printResult) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(printResult);
          link.target = "_blank";
          link.download = agentPrintActionRequest.dossierNumber + ".pdf";
          document.body.append(link);
          link.click();
          link.remove();
        }
        setAgentPrintActionRequest({} as AgentPrintActionRequest);
      }
    })();
  }, [agentPrintActionRequest]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, type?: string) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [name]: {
          type: type ?? "text",
          value: value !== "" ? value : undefined,
        },
      },
    });
  };

  const handleDropDownEnumChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [name]: {
          type: "enum",
          value: value !== "" ? value : undefined,
        },
      },
    });
    setDoSearch(true);
  };

  const handleDropDownStringChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    type?: string
  ) => {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [name]: {
          type: type ?? "text",
          value: value !== "" ? value : undefined,
        },
      },
    });
    setDoSearch(true);
  };

  const handleDateChange = (date: Date | null, id: string) => {
    switch (id) {
      case "createdDate":
        setCreatedDate(date);
        break;
      case "departureDate":
        setDepartureDate(date);
        break;
      case "returnDate":
        setReturnDate(date);
        break;
      default:
        break;
    }
    const dateValue = date
      ? {
          year: getYear(date),
          month: getMonth(date) + 1,
          day: getDate(date),
        }
      : undefined;
    setFilter({
      ...filter,
      page: 1,
      columns: {
        ...filter.columns,
        [id]: {
          type: !dateValue && id === "createdDate" ? "dateFrom" : "date",
          value:
            !dateValue && id === "createdDate"
              ? {
                  year: getYear(creationDate),
                  month: getMonth(creationDate) + 1,
                  day: getDate(creationDate),
                }
              : dateValue,
        },
      },
    });
    setDoSearch(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setDoSearch(true);
    }
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
    setFilter({ ...filter, page: page });
    setDoSearch(true);
  };

  const handleSort = (sortField: string) => {
    if (filter.sort.name === sortField) {
      setFilter({ ...filter, sort: { name: sortField, asc: !filter.sort.asc } });
    } else {
      setFilter({ ...filter, sort: { name: sortField, asc: true } });
    }
    setCurrentPage(1);
    setDoSearch(true);
  };

  const handlePrintAction = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    dossier: DossierViewResult,
    actionId: number
  ) => {
    event.preventDefault();
    setAgentPrintActionRequest({
      ...agentPrintActionRequest,
      id: actionId,
      dossierNumber: dossier.number,
      dossierId: dossier.dossierId,
    });
  };

  return (
    <>
      {!isEmpty(entryStatusEnums) && !isEmpty(countries) ? (
        <section className="section">
          <div className="portal">
            <h2>{pageData?.content?.general?.title}</h2>
            <div className="portal__container">
              <div className="portal__wrapper">
                <div className="portal__header">
                  <div className="portal__header--items portal__header--items--dossier">
                    <div className="portal__header--item"></div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label portal__header--label-sort-desc"
                        onClick={() => handleSort("number")}
                      >
                        {t("PORTAL.DOSSIER")}
                        <Icon name="sort" />
                      </label>
                      <input
                        type="text"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["number"].value}
                        id="number"
                        onChange={(event) => handleInputChange(event)}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("status")}
                      >
                        {t("PORTAL.STATUS")}
                        <Icon name="sort" />
                      </label>
                      <select
                        className="form__input"
                        defaultValue={undefined}
                        onChange={(event) => handleDropDownEnumChange(event)}
                        id="status"
                      >
                        {entryStatusEnums.map((status, index) => {
                          return (
                            <option key={index} value={status.key}>
                              {status.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("customEntryStatus")}
                      >
                        {t("PORTAL.CUSTOM_STATUS")}
                        <Icon name="sort" />
                      </label>
                      <select
                        className="form__input"
                        defaultValue={undefined}
                        onChange={(event) => handleDropDownStringChange(event)}
                        id="customEntryStatus"
                      >
                        {customEntryStatusList.map((customStatus, index) => {
                          return (
                            <option key={index} value={customStatus.name}>
                              {customStatus.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("createdDate")}
                      >
                        {t("PORTAL.CREATED")}
                        <Icon name="sort" />
                      </label>
                      <DateSelector
                        id="createdDate"
                        date={createdDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("agentName")}
                      >
                        {t("PORTAL.AGENT")}
                        <Icon name="sort" />
                      </label>
                      <input
                        type="text"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["agentName"].value}
                        id="agentName"
                        onChange={(event) => handleInputChange(event)}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("contact")}
                      >
                        {t("PORTAL.CONTACT")}
                        <Icon name="sort" />
                      </label>
                      <input
                        type="text"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["contact"].value}
                        id="contact"
                        onChange={(event) => handleInputChange(event)}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("departureDate")}
                      >
                        {t("PORTAL.DEPARTUREDATE")}
                        <Icon name="sort" />
                      </label>
                      <DateSelector
                        id="departureDate"
                        date={departureDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("returnDate")}
                      >
                        {t("PORTAL.RETURNDATE")}
                        <Icon name="sort" />
                      </label>
                      <DateSelector id="returnDate" date={returnDate} onChange={handleDateChange} />
                    </div>
                    <div className="portal__header--item">
                      <label className="form__label portal__header--label">
                        {t("PORTAL.MAINBOOKER")}
                      </label>
                      <input
                        type="text"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["pax"].value}
                        id="pax"
                        onChange={(event) => handleInputChange(event, "list")}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("firstProductName")}
                      >
                        {t("PORTAL.MAIN_PRODUCT")}
                        <Icon name="sort" />
                      </label>
                      <input
                        type="text"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["firstProductName"].value}
                        id="firstProductName"
                        onChange={(event) => handleInputChange(event)}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                    <div className="portal__header--item">
                      <label className="form__label portal__header--label">
                        {t("PORTAL.COUNTRIES")}
                      </label>
                      <select
                        className="form__input"
                        defaultValue={undefined}
                        onChange={(event) => handleDropDownStringChange(event, "list")}
                        id="countryCodes"
                      >
                        {countries.map((country, index) => {
                          return (
                            <option key={index} value={country.iso2}>
                              {country.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="portal__header--item">
                      <label
                        className="form__label portal__header--label"
                        onClick={() => handleSort("totalPrice")}
                      >
                        {t("PORTAL.TOTAL")}
                        <Icon name="sort" />
                      </label>
                      <input
                        type="number"
                        className="form__input"
                        placeholder=""
                        value={filter.columns["totalPrice"].value}
                        id="totalPrice"
                        onChange={(event) => handleInputChange(event, "number")}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="portal__list">
                  {fetchDossiers ? (
                    <Loader loaderText={t("PORTAL.LOADING_DOSSIERS")} />
                  ) : isEmpty(dossierList) ? (
                    <div
                      className={buildClassName([
                        "portal__list--empty",
                        error && "portal__list--error",
                      ])}
                    >
                      {t(error ? "PORTAL.ERROR" : "PORTAL.NO_DOSSIERS")}
                    </div>
                  ) : (
                    dossierList.map((dossier, index) => {
                      return (
                        <div
                          className="portal__list--items portal__list--items--dossier"
                          key={index}
                        >
                          <div className="portal__list--btn">
                            <button className="cta cta--secondary cta--withicon portal__list--icon">
                              <Icon name="print" className="icon--ui-print"></Icon>
                              <Icon name="arrow" className="icon--arrow"></Icon>
                            </button>
                            <div className="portal__dropdown">
                              {dossier.status == 0 && (
                                <Link
                                  href="#"
                                  className="portal__dropdown--link"
                                  onClick={(e) => handlePrintAction(e, dossier, offerPrintId)}
                                >
                                  <Icon name="print" className="icon--ui-print"></Icon>
                                  {t("PORTAL.PRINT_QUOTE_WEBSITE")}
                                </Link>
                              )}
                              {dossier.status == 2 && (
                                <Link
                                  href="#"
                                  className="portal__dropdown--link"
                                  onClick={(e) => handlePrintAction(e, dossier, orderPrintId)}
                                >
                                  <Icon name="print" className="icon--ui-print"></Icon>
                                  {t("PORTAL.PRINT_PURCHASE_ORDER")}
                                </Link>
                              )}
                              {dossier.status == 2 &&
                                dossier.customEntryStatus == "Klaar voor vertrek" && (
                                  <Link
                                    href="#"
                                    className="portal__dropdown--link"
                                    onClick={(e) => handlePrintAction(e, dossier, voucherPrintId)}
                                  >
                                    <Icon name="print" className="icon--ui-print"></Icon>
                                    {t("PORTAL.PRINT_TRAVELDOCUMENT_VOUCHER")}
                                  </Link>
                                )}
                            </div>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.number}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{t(`STATUSTYPE.${dossier.status.toString()}`)}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.customEntryStatus}</span>
                          </div>
                          <div className="portal__list--item portal__list--item-right">
                            <span>{format(new Date(dossier.createdDate), "dd-MM-yyyy")}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.agentName}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.contact}</span>
                          </div>
                          <div className="portal__list--item portal__list--item-right">
                            <span>
                              {dossier.departureDate
                                ? format(new Date(dossier.departureDate), "dd-MM-yyyy")
                                : ""}
                            </span>
                          </div>
                          <div className="portal__list--item portal__list--item-right">
                            <span>
                              {dossier.returnDate
                                ? format(new Date(dossier.returnDate), "dd-MM-yyyy")
                                : ""}
                            </span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.pax.find((p) => p.isMainBooker)?.name}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.firstProductName?.slice(0, 40)}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.countryCodes.join(",")}</span>
                          </div>
                          <div className="portal__list--item">
                            <span>{dossier.totalPrice.toFixed(2)}</span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="portal__footer">
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={(page) => changePage(page)}
                  maxWidth={400}
                  pageItemClassName="portal__footer--page"
                  activeItemClassName="portal__footer--page-active"
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default DossierList;
