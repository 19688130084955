import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getLocale } from "../../i18n/languages";
import nlBE from "date-fns/locale/nl-BE";
import fr from "date-fns/locale/fr";

interface DateSelectorProps {
  id: string;
  date: Date | null;
  onChange?: (date: Date | null, id: string) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({ id, date, onChange }) => {
  const { language } = useI18next();
  const locale = getLocale(language);
  registerLocale("nl-BE", nlBE);
  registerLocale("fr", fr);

  const handleChange = (date: Date | null) => {
    if (onChange) {
      onChange(date, id);
    }
  };

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      locale={locale}
      id={id}
      selected={date}
      onChange={(date) => handleChange(date)}
      wrapperClassName="datepicker"
      showIcon={true}
    />
  );
};

export default DateSelector;
